import React from "react"
import { isMobile, withOrientationChange, isTablet } from "react-device-detect"

import Input from "@/components/core/Input/Input"
import Button from "@/components/core/Button/Button"
import QuantitySelector from "@/components/cart/QuantitySelector"
import { getPresetUrl, getRowCols } from "@/utils"
import useWindowResize from "@/hooks/useWindowResize"
import { sanitizeInnerHtml } from "@/utils/helper"
import DisplayDiscountPrice from "@/components/Shared/DisplayDiscountPrice"

const InstallDesignServicesView = ({
  handleCheckAvailability = () => {},
  authorData = {},
  zipCodeError = {},
  zipCodeInput = "",
  handleChangeZipCode = () => {},
  i18n = {},
  handleChange = () => {},
  handleAddToCart = () => {},
  quantity = {},
  zipCodeCheck = () => {},
  availableServices = [],
  productAnalyticsData = {},
  productData = [],
  handleClickChangeBtn = () => {},
  zipCodeChecked = false,
}) => {
  const [width] = useWindowResize()
  const {
    title = "",
    checkAvailabilityText = "",
    searchButtonLabel = "",
    individualServiceNotAvailableText = "",
    changeZipCodeLabel = "",
    serviceAvailableText = "",
    serviceNotAvailableText = "",
    serviceAvailableDescription = "",
    serviceNotAvailableDescription = "",
    isZipCodeLookup = "",
  } = authorData
  const { zipCode = "", search = "", addToCart = "" } = i18n
  const rowCols = getRowCols(width, isMobile, isTablet)

  return (
    <React.Fragment>
      <div className="install-design-services__container">
        <div className=" install-design-services__section">
          {title && <p className="install-design-services__title">{title}</p>}
          {isZipCodeLookup ? (
            <p className="install-design-services__zip-code-message">
              {sanitizeInnerHtml(checkAvailabilityText)}
            </p>
          ) : null}
          {isZipCodeLookup ? null : (
            <>
              {!zipCodeChecked ? (
                <p className="install-design-services__zip-code-message">
                  {sanitizeInnerHtml(checkAvailabilityText)}
                </p>
              ) : null}
              {zipCodeChecked && availableServices?.length ? (
                <>
                  <p className="install-design-services__zip-code-message">
                    {sanitizeInnerHtml(serviceAvailableText)}
                  </p>
                  <p className="install-design-services__success-error-message">
                    {sanitizeInnerHtml(serviceAvailableDescription)}
                  </p>
                </>
              ) : null}
              {zipCodeChecked && !availableServices?.length ? (
                <>
                  <p className="install-design-services__zip-code-message">
                    {sanitizeInnerHtml(serviceNotAvailableText)}
                  </p>
                  <p className="install-design-services__success-error-message">
                    {sanitizeInnerHtml(serviceNotAvailableDescription)}
                  </p>
                </>
              ) : null}
              <React.Fragment>
                <div className="product-card-service__zipcode-availability">
                  {zipCodeChecked ? (
                    <>
                      <p className="install__zipcode">{zipCodeInput}</p>
                      <p
                        tabIndex="0"
                        role="link"
                        className="install-service__eligible-service-edit"
                        onClick={handleClickChangeBtn}
                      >
                        {" "}
                        {changeZipCodeLabel}
                      </p>
                    </>
                  ) : (
                    <>
                      <Input
                        id="zipcode-install-design-service"
                        type="text"
                        maxLength={60}
                        placeholder={`${zipCode}`}
                        label={`${zipCode}`}
                        value={zipCodeInput}
                        customClass={`${
                          zipCodeError?.show
                            ? "product-card-service__zipcode-input-error"
                            : ""
                        }`}
                        onKeyDown={e =>
                          e?.key === "Enter" && handleCheckAvailability()
                        }
                        onChange={handleChangeZipCode}
                        onBlur={zipCodeCheck}
                      />
                    </>
                  )}
                  {zipCodeInput?.length && !zipCodeChecked ? (
                    <Button
                      role="button"
                      className="black intall-service-search"
                      label={searchButtonLabel ?? search}
                      type=" "
                      disabled={zipCodeInput?.length < 5}
                      onClick={handleCheckAvailability}
                      useDefaultClass={true}
                    />
                  ) : null}
                </div>
                {zipCodeError?.show ? (
                  <p className="error">{zipCodeError?.message}</p>
                ) : null}
              </React.Fragment>
            </>
          )}
          <div className="install-services-lists">
            {productData?.length
              ? productData.map(item => {
                  const {
                    name = "",
                    description = "",
                    price = "n/a",
                    currencySign,
                    swatchUrl,
                    presetConfigs,
                    images,
                    skuId,
                    discountedPrice = 0,
                  } = item
                  const imgUrl = getPresetUrl(
                    width,
                    rowCols,
                    swatchUrl,
                    images,
                    presetConfigs,
                    true
                  )
                  return (
                    <div
                      className={`install-services-lists__card ${
                        (zipCodeChecked && availableServices?.length) ||
                        isZipCodeLookup
                          ? "show-add-cart-button"
                          : ""
                      }`}
                      key={name}
                    >
                      <div className="install-services-lists__image">
                        <img className="search-icon" src={imgUrl} alt={name} />
                      </div>
                      <div className="install-services-lists__information">
                        <div className="install-services-lists__detail-section">
                          <p className="install-services-lists__detail-section--name">
                            {name}
                          </p>
                          <p className="install-services-lists__detail-section--description">
                            {description}
                          </p>
                        </div>
                        {availableServices?.includes(skuId) ||
                        isZipCodeLookup ? (
                          <div className="install-services-lists__price-section">
                            <p className="install-services-lists__price">
                              <DisplayDiscountPrice
                                discountedPrice={discountedPrice}
                                price={price}
                                currencySign={currencySign}
                              />
                            </p>
                          </div>
                        ) : null}
                        <div className="clear"></div>
                      </div>

                      {(zipCodeChecked && availableServices?.includes(skuId)) ||
                      isZipCodeLookup ? (
                        <div className="install-services-lists__price-and-cart">
                          <div className="install-services-lists__cart">
                            <div className="cartAction mx-0">
                              <div className="quantity-selector-container">
                                <QuantitySelector
                                  doNotRequiredAnalyticsData={true}
                                  productData={productAnalyticsData}
                                  value={quantity[skuId]}
                                  onChange={qty => handleChange(qty, skuId)}
                                  isPDPQuantitySelector={true}
                                />
                              </div>
                              <div className="add-to-cart-container">
                                <Button
                                  role="button"
                                  label={addToCart}
                                  onClick={() => handleAddToCart(item)}
                                  size="large"
                                  type="primary"
                                  flexible={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : zipCodeChecked &&
                        availableServices?.length &&
                        !availableServices?.includes(skuId) ? (
                        <div className="install-services-lists__price-and-cart">
                          <p className="individual-service-not-available">
                            {individualServiceNotAvailableText}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  )
                })
              : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withOrientationChange(InstallDesignServicesView)
